






























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, ValidationRule } from 'vuelidate/lib/validators'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import FileInput from '@/app/ui/components/FileInput/index.vue'
import controller from '@/app/ui/controllers/BrandController'
import { EventBusConstants } from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'

interface ValidationInterface {
  form: {
    name: {
      required: () => ValidationRule;
    },
    image: {
      required: () => ValidationRule;
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    TextInput,
    Button,
    FileInput,
    Modal,
    LoadingOverlay,
  },
})
export default class CreateBrand extends Vue {
  controller = controller
  form: { name: string; image: Blob | null } = {
    name: '',
    image: null,
  }
  confirmationModal = false
  successModal = false

  @Validations()
  validations(): ValidationInterface {
    return {
      form: {
        name: { required },
        image: { required },
      },
    }
  }

  private changeImage(file: File): void {
    this.form.image = file
  }

  private createBrand(): void {
    controller.createBrand({
      name: <string>this.form.name,
      image: <Blob>this.form.image,
    })
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({ name: 'BrandListPage' })
  }

  private setConfirmationModal(bool: boolean): void {
    this.confirmationModal = bool
  }

  @Watch('controller.statusCreateUpdateBrand')
  onStatusCreateUpdateBrandChanged(status: string): void {
    if (status !== '') {
      if (status === EventBusConstants.CREATE_BRAND_SUCCESS) {
        this.setConfirmationModal(false)
        this.successModal = true
      }
    }
  }

  beforeDestroy(): void {
    controller.setStatusCreateUpdateBrand('')
  }
}
